<template>
  <div @click="togglePanel()" class="conflicted-projects-toggle">
    <img src="../../../assets/icons/svg/ic_report.svg" class="toggle-icon" alt="report icon"/>
    <span class="conflict-count">{{ conflictedProjects.length }}</span>

    <!-- Panel -->
    <div v-if="isPanelShown" class="conflicts-panel">

      <!-- Inner panel to add overflow with padding -->
      <div class="panel-inner">
        <p v-if="conflictedProjects.length" class="panel-title">Conflicting projects detected</p>
        <p v-else class="panel-title">No conflicting projects detected</p>
        <p v-if="conflictedProjects.length" class="panel-text">The following projects are conflicting because of changes within Jira.</p>

        <!-- Conflicted projects -->
        <router-link v-for="project in conflictedProjects"
                     :key="project.id"
                     :to="{name: ROUTE_NAMES_CONFLICT_MANAGER.CONFLICT_MANAGER, params: {projectId: project.id}}"
                     class="conflicted-project">
          <p class="project-name">{{ project.name }}</p>
          <img src="../../../assets/icons/svg/ic_next_active.svg" class="arrow-icon" alt="arrow"/>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import {GET_CONFLICTED_PROJECTS} from "@/store/project/actions";
import {ROUTE_NAMES_CONFLICT_MANAGER} from "@/router/modules/conflictManager";

export default {
  name: "ConflictedProjects",
  setup(_, {root}) {
    const store = root.$store;
    const isPanelShown = ref(false);

    function togglePanel() {
      // Before opening the panel, make sure to get the latest data. If it's already open, we just need to close it.
      if (isPanelShown.value) {
        isPanelShown.value = !isPanelShown.value;
      } else {
        getConflictedProjects().then(response => {
          if (response) {
          // if (response && conflictedProjects.value.length) {
            isPanelShown.value = !isPanelShown.value;
          }
        });
      }
    }

    /** Conflicted projects data **/
    getConflictedProjects(); // Get the conflicted project upon page load.
    const conflictedProjects = computed(() => store.getters.getConflictedProjects);

    function getConflictedProjects() {
      return store.dispatch(GET_CONFLICTED_PROJECTS);
    }

    return {
      ROUTE_NAMES_CONFLICT_MANAGER,
      isPanelShown,
      togglePanel,

      projects: [
        {
          id: 3,
          name: 'Billy',
        },
        {
          id: 66,
          name: 'DTT-design',
        },
        {
          id: 6666,
          name: 'AndersDenken',
        },
        {
          id: 656,
          name: 'Avia',
        },
        {
          id: 67666,
          name: 'Dragzter',
        },
      ],

      /** Conflicted projects data **/
      conflictedProjects
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.conflicted-projects-toggle {
  @include hover-active-pointer();
  align-items: center;
  background-color: var(--gray-main);
  border-radius: rem(5);
  display: flex;
  height: rem(35);
  justify-content: center;
  position: relative;
  width: rem(55);

  .toggle-icon {
    height: rem(16);
    margin-right: rem(6);
    width: rem(16);
  }

  .conflict-count {
    color: var(--red-light);
    font-family: "Open Sans";
    font-size: rem(14);
    font-weight: bold;
    line-height: rem(19);
  }
}

// PANEL
.conflicts-panel {
  @include position-elevation(absolute, $top: calc(100% + 6px), $left: 0, $elevation: var(--conflicts-panel-index));
  @include on-hover {
    cursor: default;
  }
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(4);
  display: flex;
  max-height: rem(440);
  padding: rem(24) rem(6) 0 rem(16);
  width: rem(370);

  .panel-inner {
    overflow-y: auto;
    padding-right: rem(20);

    &::-webkit-scrollbar {
      width: rem(4);
    }
  }

  .panel-title {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(16);
  }

  .panel-text {
    font-family: "Open Sans";
    font-size: rem(16);
    line-height: rem(22);
    margin-bottom: rem(20);
  }
}

// CONFLICTED PROJECT BOX
.conflicted-project {
  @include hover-active-pointer();
  align-items: center;
  background-color: var(--gray-main);
  border-radius: rem(5);
  display: flex;
  height: rem(62);
  justify-content: space-between;
  padding: 0 rem(20);

  .project-name {
    font-family: "Open Sans";
    font-size: rem(16);
    font-weight: 600;
    line-height: rem(22);
  }

  .arrow-icon {
    height: rem(24);
    width: rem(24);
  }

  &:not(:last-child) {
    margin-bottom: rem(8);
  }
}
</style>
