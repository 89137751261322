<template>
  <div class="breadcrumbs-wrapper">
    <!-- Back button -->
    <router-link :to="{name: $route.meta.backButtonRoute, params: {projectId, orderId}}" class="go-back">
      <img src="../../assets/icons/svg/ic_back_active_dark_grey.svg" class="icon" alt="next icon"/>
    </router-link>

    <!-- Breadcrumbs -->
    <router-link v-for="(breadcrumb, idx) in breadcrumbList"
                 :key="idx"
                 :to="{name: breadcrumb.name}"
                 :disabled="breadcrumb.name === $route.name"
                 :active-class="breadcrumb.name === $route.name ?'active-breadcrumb' : ''"
                 class="breadcrumb-text">
      {{ breadcrumbText(breadcrumb) }}
      <img v-if="displayArrow(idx)"
           src="../../assets/icons/svg/ic_next_active_dark_grey.svg"
           class="breadcrumb-icon"
           alt="next icon"/>
    </router-link>
  </div>
</template>

<script>
import {ref, watch} from "@vue/composition-api";
import {ROUTE_NAMES_PROJECT_OVERVIEW} from '@/router/modules/projectOverview';
import {computed} from "@vue/composition-api";

export default {
  name: "Breadcrumbs",
  setup(props, {root}) {
    const store = root.$store;

    const breadcrumbList = ref([]);

    updateList();

    function updateList() {
      breadcrumbList.value = root.$route.meta.breadcrumb;
    }

    function displayArrow(index) {
      return (index + 1) !== breadcrumbList.value.length;
    }

    // Get the breadcrumb's text.
    const breadcrumbText = (breadcrumb) => breadcrumb.textWithName ? breadcrumb.textWithName(projectDetails.value?.name) : breadcrumb.text;

    // Make sure to update the breadcrumbs if the route changes.
    watch(() => root.$route, () => {
      updateList();
    });

    /** Project details **/
    const projectDetails = computed(() => store.getters.getSelectedProject);

    /** URL params **/
    const projectId = root.$route.params.projectId;
    const orderId = root.$route.params.orderId;

    return {
      ROUTE_NAMES_PROJECT_OVERVIEW,
      breadcrumbList,
      displayArrow,
      breadcrumbText,

      /** Project details **/
      projectDetails,

      /** URL params **/
      projectId,
      orderId,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.breadcrumbs-wrapper {
  align-items: center;
  display: flex;
}

.breadcrumb-text {
  align-items: center;
  color: var(--black-light);
  display: flex;
  font-size: rem(17);
  line-height: rem(20);
  text-transform: uppercase;
}

.breadcrumb-icon {
  height: rem(24);
  margin: 0 rem(11);
  opacity: 0.3;
  width: rem(24);
}

.active-breadcrumb {
  color: var(--gray-light-03);

  &:hover {
    cursor: default;
  }
}

.go-back {
  align-items: center;
  background-color: white;
  border: rem(1) solid #ECECEC;
  border-radius: rem(6);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(0,0,0,0);
  display: flex;
  height: rem(42);
  justify-content: center;
  margin-right: rem(32);
  width: rem(42);

  .icon {
    height: rem(24);
    width: rem(24);
  }
}
</style>
