<template>
  <div class="bar-wrapper">
    <!-- Logo -->
    <router-link :to="{name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW}" class="logo-wrapper">
      <img src="../../assets/icons/x2/img_billy_logo@2x.png" class="bar-logo" alt="Logo"/>
    </router-link>

    <!-- Menu items -->
    <div class="menu-wrapper">
      <div v-for="item in menuItems" :key="item.id" class="menu-item-outter">

        <!-- Is a dropdown -->
        <div v-if="item.hasAccess && item.dropdown"
             :class="{'active': selectedMenuId === item.id}"
             class="menu-item menu-dropdown-wrapper">
          <p class="item-link">{{ item.text }}</p>
          <div class="menu-dropdown">
            <div class="dropdown-inner">
              <DLink v-for="dropdownItem in item.dropdown"
                     @click.native="menuItemClicked(item.id)"
                     :class="{'active': selectedMenuId === dropdownItem.id}"
                     :key="dropdownItem.id"
                     :router-link="{name: dropdownItem.routeName}"
                     route-active-class="active"
                     :text="dropdownItem.text"
                     class="dropdown-item"/>
            </div>
          </div>
          <img src="../../assets/icons/svg/ic_dropdown_active.svg" class="caret-icon" alt="cart"/>
        </div>

        <!-- Is a simple menu item -->
        <div v-else-if="item.hasAccess"
             :class="{'active': selectedMenuId === item.id}"
             @click="menuItemClicked(item.id)"
             class="menu-item">
          <DLink :text="item.text" :router-link="{name: item.routeName}" class="item-link"/>
        </div>
      </div>
    </div>

    <!-- User and logout -->
    <div class="user-wrapper">

      <!-- Conflicted projects -->
      <ConflictedProjects/>

      <!-- Username -->
      <p class="user">{{ user }}</p>

      <!-- Logout -->
      <DButton @click.native="logout"
               text="Log out"
               :icon="require('../../assets/icons/svg/ic_logout_primary.svg')"
               class="button-border logout-button"/>

    </div>
  </div>
</template>

<script>
import {SET_LOGOUT} from "@/store/auth/mutations";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import ConflictedProjects from "@/components/partials/conflictedProjects/ConflictedProjects";
import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import MenuItem from "@/models/menu/MenuItem";
import {computed} from "@vue/composition-api";
import {SET_SELECTED_MENU_ID} from "@/store/mutations";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import {ROUTE_NAMES_MANAGE_LOGS} from "@/router/modules/manageLogs";
import {ROUTE_NAMES_CONFLICT_MANAGER} from "@/router/modules/conflictManager";
import DButton from "@/components/elements/DButton";
import {ROUTE_NAMES_USERS} from "@/router/modules/users";
import {USER_ROLES} from "@/models/users/User";
import LocalDataHandler from "@/utils/handlers/LocalDataHandler";
import {ROUTE_NAMES_ACTIVE_ORDERS} from "@/router/modules/activeOrders";
import {ROUTE_NAMES_CALCULATIONS} from "@/router/modules/calculations";
import DLink from "@/components/elements/basicUI/DLink";

export default {
  name: "TopBar",
  components: {
    DLink,
    DButton,
    ConflictedProjects
  },
  props: {
    user: {
      type: String,
      required: true
    }
  },
  setup(props, {root}) {
    const store = root.$store;
    const router = root.$router;
    const route = root.$route;
    console.log(route)

    /** User **/
    const userProfile = computed(() => store.getters.getUserProfile);

    /** Menu items **/
    const selectedMenuId = computed(() => store.getters.getSelectedMenuId);

    function initializeMenu() {
      // Find the menu item that's supposed to be our current menu item.
      const currentMenuItem = matchMenuItemByRouteName(route.name);

      // Set it as selected.
      setSelectedMenu(currentMenuItem.id);
    }

    const menuItems = [
      MenuItem.create({
        id: 0,
        text: 'Projects',
        routeName: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
        assocRouteNames: [
          ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
          ROUTE_NAMES_ORDER.ORDER_DETAILS,
          ROUTE_NAMES_ORDER.ORDER_PROCESSED,
          ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS,
          ROUTE_NAMES_CONFLICT_MANAGER.CONFLICT_MANAGER,
        ],
        hasAccess: true
      }),
      MenuItem.create({
        id: 1,
        text: 'Active orders',
        routeName: ROUTE_NAMES_ACTIVE_ORDERS.ACTIVE_ORDERS,
        assocRouteNames: [],
        hasAccess: true,
      }),
      // MenuItem.create({
      //   id: 3,
      //   text: 'Calculations',
      //   routeName: '',
      //   assocRouteNames: [
      //     ROUTE_NAMES_CALCULATIONS.CALCULATIONS_ESTIMATIONS, ROUTE_NAMES_CALCULATIONS.CALCULATIONS_AFTERPAY,
      //   ],
      //   hasAccess: true,
      //   dropdown: [
      //     {
      //       id: 33,
      //       routeName: ROUTE_NAMES_CALCULATIONS.CALCULATIONS_ESTIMATIONS,
      //       text: 'Estimations'
      //     },
      //     {
      //       id: 22,
      //       routeName: ROUTE_NAMES_CALCULATIONS.CALCULATIONS_AFTERPAY,
      //       text: 'Afterpay'
      //     },
      //   ]
      // }),
      MenuItem.create({
        id: 4,
        text: 'Users',
        routeName: ROUTE_NAMES_USERS.USERS_OVERVIEW,
        assocRouteNames: [],
        hasAccess: userProfile.value.roleId === USER_ROLES.SUPER_ADMIN
      }),
    ]
    initializeMenu();

    function matchMenuItemByRouteName(routeName) {
      return menuItems.find(menuItem => {
        if (menuItem.routeName === routeName) return true;
        return !!menuItem.assocRouteNames.find(name => name === routeName);
      });
    }

    function menuItemClicked(id) {
      setSelectedMenu(id);
    }

    function setSelectedMenu(id) {
      store.commit(SET_SELECTED_MENU_ID, id);
    }

    /** Logout **/
    function logout() {
      store.commit(SET_LOGOUT);
      router.push({name: ROUTE_NAMES_AUTH.LOGIN});
    }

    return {
      ROUTE_NAMES_PROJECT_OVERVIEW,

      /** Menu items **/
      selectedMenuId,
      menuItems,
      menuItemClicked,

      /** Logout **/
      logout
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.bar-wrapper {
  @include position-elevation(fixed, $top: 0, $right: 0, $left: 0, $elevation: 100);
  align-items: center;
  background-color: white;
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  display: flex;
  justify-content: space-between;
  //overflow: hidden;
  padding: var(--top-bar-padding-top) rem(40);
  width: 100%;

  .logo-wrapper {
    @include hover-active-pointer;

    .bar-logo {
      height: rem(42);
      vertical-align: middle;
      width: rem(98);
    }
  }
}

// Menu items
.menu-wrapper {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-right: auto;
  margin-left: rem(45);
}

.menu-item-outter {
  flex-grow: 1;
  flex-shrink: 0;

  .item-link {
    display: inline-block;
    font-family: var(--open-sans);
    font-weight: var(--open-sans-weight-bold);
  }

  &:not(:last-child) {
    margin-right: rem(32);
  }

  .menu-item {
    &.active {
      position: relative;

      &::after {
        @include position(absolute, $right: 0, $bottom: calc(-100% - var(--top-bar-padding-top) + 11px), $left: 0);
        content: " ";
        background-color: var(--red-main);
        border-radius: rem(4);
        height: rem(5);
      }
    }
  }
}

// User information
.user-wrapper {
  align-items: center;
  display: flex;

  .user {
    color: var(--gray-light-03);
    font-family: var(--open-sans);
    font-weight: bold;
    margin-left: rem(48);
    margin-right: rem(47);
  }

  .logout-button {
    align-items: center;
    display: flex;
    justify-content: center;
    width: rem(141);

    .logout-icon {
      margin-left: rem(13);
      width: rem(24);
    }
  }
}

// Dropdown
.menu-dropdown-wrapper {
  @include hover-active-pointer;
  position: relative;
  width: rem(119);

  &:hover {
    .menu-dropdown {
      display: inline-block;
    }
  }

  .menu-dropdown {
    @include position(absolute, $top: 0, $left: rem(-13));
    display: none;
    padding-top: calc(var(--top-bar-padding-top) + 40px);

    // Added so the user is able to simply hover to toggle the dropdown.
    .dropdown-inner {
      background-color: white;
      border: 1px solid var(--gray-light-04);
      border-radius: rem(5);
      box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);
      overflow: hidden;
    }
  }

  .dropdown-item {
    font-family: var(--open-sans);
    padding: rem(8) rem(23);
    text-align: left;
    width: 100%;

    &.active {
      background-color: var(--gray-main);
      font-weight: var(--open-sans-weight-bold);
    }
  }

  .caret-icon {
    display: inline-block;
    height: rem(20);
    margin-left: rem(2);
    vertical-align: middle;
    width: rem(20);
  }
}
</style>
