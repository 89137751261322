export default class MenuItem {
  constructor({id, text, routeName, assocRouteNames, hasAccess, dropdown = null}) {
    this.id = id;
    this.text = text;
    this.routeName = routeName;
    this.dropdown = dropdown;
    this.assocRouteNames = assocRouteNames;
    this.hasAccess = hasAccess;
  }

  static create(data) {
    return new MenuItem(data);
  }
}