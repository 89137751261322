<template>
  <div id="page-layout" class="page-layout">
    <TopBar v-if="userProfile" :user="userProfile.username" class="top-bar"/>

    <!-- Breadcrumbs -->
    <Breadcrumbs v-if="$route.meta.breadcrumb" class="breadcrumbs"/>

    <!-- Page content -->
    <div class="page-content">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>

<script>
import TopBar from "../components/partials/TopBar";
import Breadcrumbs from "@/components/partials/Breadcrumbs";
import {computed} from "@vue/composition-api"
import {GET_USER_PROFILE} from "../store/user/actions";

export default {
  name: "Layout",
  components: {
    Breadcrumbs,
    TopBar
  },
  setup(_, {root}) {
    const store = root.$store;

    /** User **/
    getUserProfile();
    const userProfile = computed(() => store.getters.getUserProfile);

    function getUserProfile() {
      return store.dispatch(GET_USER_PROFILE);
    }

    return {
      /** User **/
      userProfile
    }
  }
}
</script>

<style lang="scss">
@import "../assets/css/base.mixins";
@import "../assets/css/layout.shared";

/** Layout **/
.page-layout {
  align-items: flex-start;
  background-color: var(--gray-main);
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  padding-bottom: rem(50);
  padding-top: var(--top-bar-height);
  overflow-x: auto;
  width: 100vw;
}

.page-content {
  background-color: white;
  border-radius: rem(10);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
  margin: rem(35) auto 0 auto;
  min-height: 75%;
  padding: rem(77) rem(40);
  text-align: center;
  width: calc(100% - 80px);
}

.page-wrapper {
  height: 100%;
}

/** Hour info **/
.hour-indications {
  flex-grow: 1;
}

/** Breadcrumbs **/
.breadcrumbs {
  margin: 32px auto rem(32) auto;
  width: calc(100% - 80px);
}

/** Texts **/
.main-title {
  @extend .mont-bold;
  color: var(--black-light);
  font-size: rem(40);
  line-height: rem(49);

  &.page-title {
    text-align: left;
  }
}

.no-results-table-text {
  color: var(--gray-light-03);
  font-family: var(--montserrat-weight-bold);
  font-size: rem(24);
  letter-spacing: 0;
  line-height: rem(29);
  margin-top: rem(80);
  text-align: center;
}

/** Dividers **/
.divider-line-thick {
  background-color: var(--gray-main);
  border-radius: rem(8);
  height: rem(16);
  margin-top: rem(31);
  width: 100%;
}

.divider-line-1 {
  background-color: var(--gray-light-02);
  height: rem(1);
  margin-bottom: rem(40);
  margin-top: rem(40);
  width: 100%;
}

.divider-line {
  background-color: var(--gray-main);
  height: rem(2);
  margin-bottom: rem(42);
  margin-top: rem(36);
  width: 100%;
}

.divider-line-vertical {
  background-color: var(--gray-light-04);
  height: rem(45);
  margin: auto 0;
  width: rem(2);
}

/** Buttons **/
.edit-button-small {
  @include hover-active-pointer();
  align-items: center;
  border: rem(1) solid var(--gray-light-02);
  border-radius: rem(6);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(0, 0, 0, 0);
  display: inline-flex;
  height: rem(26);
  flex-shrink: 0;
  justify-content: center;
  width: rem(26);

  .icon {
    height: rem(16);
    width: rem(16);
  }
}

/** Images/icons **/

/** Shared colors **/
.success-purple {
  color: var(--purple-light);
  font-weight: bold;
}

.success-light-blue {
  color: var(--blue-light);
  font-weight: bold;
}

.success-brown {
  color: var(--brown-light);
  font-weight: bold;
}

.success-orange {
  color: var(--orange-light);
  font-weight: bold;
}

/** Search with filters **/
.search-with-filters {
  align-items: center;
  display: flex;
  margin-bottom: rem(24);

  .search {
    flex-basis: 100%;
    height: rem(52);
    margin-right: rem(21);
  }

  .filters-button {
    flex-basis: rem(129);
  }

  .date-filters {
    height: rem(52);
    margin-right: rem(16);
  }
}

.filters {
  margin-bottom: rem(24);

  .filter-title {
    font-size: rem(12);
    line-height: rem(15);
    margin-bottom: rem(7);
  }

  .search-results {

    .selected-items {
      .selected-text {
        font-size: rem(14);
        font-weight: bold;
        line-height: rem(19);
        margin-bottom: rem(15);
      }
    }

    &.has-selected-items {
      .result-item {
        &:last-child {
          margin-bottom: rem(32);
        }

        // The last .result-item can also be a selected item, in which case we do not want margin-bottom.
        &.is-selected {
          margin-bottom: 0;
        }
      }
    }

    .result-item {
      &:not(:last-child) {
        margin-bottom: rem(8);
      }
    }
  }
}

.search-field-inactive {
  display: flex;
  height: rem(52);
  padding: rem(10);
}

.selected-filter-chip {
  align-items: center;
  background-color: var(--gray-main);
  border-radius: rem(13);
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  justify-content: center;
  padding: rem(1) rem(9);

  .text {
    font-family: "Open Sans";
    font-size: rem(14);
    line-height: rem(19);
    margin-right: rem(8);
    flex-shrink: 0;
  }

  .icon {
    height: rem(14);
    width: rem(14);
  }

  &:not(:last-child) {
    margin-right: rem(8);
  }
}

/**  **/

/** Conflict manager **/
.conflict-details {
  border-bottom: rem(1) solid var(--black-light);
  border-left: rem(1) solid var(--black-light);
  border-right: rem(1) solid var(--black-light);
  padding: rem(40) rem(32);
  width: 100%;
}

.changes-chip {
  border-radius: rem(13);
  color: white;
  font-size: rem(12);
  font-weight: bold;
  line-height: rem(15);
  padding: rem(4) rem(8);
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: rem(8);
  }
}

.change-comment, .change-hours, .change-summary, .change-parent {
  background-color: var(--black-light);
}

.change-deleted {
  background-color: var(--red-light);
  color: white;
}
/**  **/
</style>
